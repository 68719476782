define("@videoly/ember-components/components/v-button-selector", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BasicDropdown
    @matchTriggerWidth={{this.matchTriggerWidth}}
    @renderInPlace={{this.renderInPlace}}
    @horizontalPosition={{this.horizontalPosition}}
    @verticalPosition={{this.verticalPosition}}
    @disabled={{@isDisabled}}
    @onOpen={{this.onOpenDropdown}}
    @onClose={{this.onCloseDropdown}} as |dd|
  >
    <dd.Trigger
      class="v-button-selector"
      ...attributes
    >
      {{#if this.isDropdownOpened}}
        <v-button
          icon-side="right"
          size="small"
          text={{@text}}
          theme="secondary"
        >
          <v-icon
            class="v-button-selector__icon v-button-selector__icon--opened"
            name="Lined_Arrow_Up"
          />
        </v-button>
      {{else}}
        <v-button
          disabled={{@isDisabled}}
          icon-side="right"
          size="small"
          text={{@text}}
          theme="secondary"
        >
          <v-icon
            class="v-button-selector__icon"
            name="Lined_Arrow_Down"
          />
        </v-button>
      {{/if}}
    </dd.Trigger>
    <dd.Content class="ember-basic-dropdown-content--default">
      {{yield}}
    </dd.Content>
  </BasicDropdown>
  
  */
  {
    "id": "yh4hy7oD",
    "block": "[[[8,[39,0],null,[[\"@matchTriggerWidth\",\"@renderInPlace\",\"@horizontalPosition\",\"@verticalPosition\",\"@disabled\",\"@onOpen\",\"@onClose\"],[[30,0,[\"matchTriggerWidth\"]],[30,0,[\"renderInPlace\"]],[30,0,[\"horizontalPosition\"]],[30,0,[\"verticalPosition\"]],[30,1],[30,0,[\"onOpenDropdown\"]],[30,0,[\"onCloseDropdown\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"Trigger\"]],[[24,0,\"v-button-selector\"],[17,3]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"isDropdownOpened\"]],[[[1,\"      \"],[10,\"v-button\"],[14,\"icon-side\",\"right\"],[14,\"size\",\"small\"],[15,\"text\",[30,4]],[14,\"theme\",\"secondary\"],[12],[1,\"\\n        \"],[10,\"v-icon\"],[14,0,\"v-button-selector__icon v-button-selector__icon--opened\"],[14,3,\"Lined_Arrow_Up\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"v-button\"],[15,\"disabled\",[30,1]],[14,\"icon-side\",\"right\"],[14,\"size\",\"small\"],[15,\"text\",[30,4]],[14,\"theme\",\"secondary\"],[12],[1,\"\\n        \"],[10,\"v-icon\"],[14,0,\"v-button-selector__icon\"],[14,3,\"Lined_Arrow_Down\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"Content\"]],[[24,0,\"ember-basic-dropdown-content--default\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[18,5,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@isDisabled\",\"dd\",\"&attrs\",\"@text\",\"&default\"],false,[\"basic-dropdown\",\"if\",\"yield\"]]",
    "moduleName": "@videoly/ember-components/components/v-button-selector.hbs",
    "isStrictMode": false
  });
  let VButtonSelectorComponent = _exports.default = (_class = class VButtonSelectorComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isDropdownOpened", _descriptor, this);
    }
    get matchTriggerWidth() {
      var _this$args$matchTrigg;
      return (_this$args$matchTrigg = this.args.matchTriggerWidth) !== null && _this$args$matchTrigg !== void 0 ? _this$args$matchTrigg : true;
    }
    get renderInPlace() {
      var _this$args$renderInPl;
      return (_this$args$renderInPl = this.args.renderInPlace) !== null && _this$args$renderInPl !== void 0 ? _this$args$renderInPl : true;
    }
    get horizontalPosition() {
      var _this$args$horizontal;
      return (_this$args$horizontal = this.args.horizontalPosition) !== null && _this$args$horizontal !== void 0 ? _this$args$horizontal : 'left';
    }
    get verticalPosition() {
      var _this$args$verticalPo;
      return (_this$args$verticalPo = this.args.verticalPosition) !== null && _this$args$verticalPo !== void 0 ? _this$args$verticalPo : 'below';
    }
    onOpenDropdown() {
      this.isDropdownOpened = true;
    }
    onCloseDropdown() {
      this.isDropdownOpened = false;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isDropdownOpened", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onOpenDropdown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onOpenDropdown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseDropdown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseDropdown"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VButtonSelectorComponent);
});
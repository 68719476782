define("@videoly/ember-components/components/v-editor", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="v-editor">
    <TrixEditor as |trix|>
      <trix.toolbar @default={{false}} />
      <trix.editor
        @initialValue={{@initialValue}}
        @trixInitialize={{@trixInitialize}}
        @trixChange={{@trixChange}}
        @trixPaste={{@trixPaste}}
        @trixSelectionChange={{@trixSelectionChange}}
        @trixFocus={{@trixFocus}}
        @trixBlur={{@trixBlur}}
        @trixFileAccept={{@trixFileAccept}}
        @trixAttachmentAdd={{@trixAttachmentAdd}}
        @trixAttachmentRemove={{@trixAttachmentRemove}}
      />
    </TrixEditor>
  </div>
  
  {{yield}}
  
  */
  {
    "id": "oAcqLbqu",
    "block": "[[[10,0],[14,0,\"v-editor\"],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"toolbar\"]],null,[[\"@default\"],[false]],null],[1,\"\\n    \"],[8,[30,1,[\"editor\"]],null,[[\"@initialValue\",\"@trixInitialize\",\"@trixChange\",\"@trixPaste\",\"@trixSelectionChange\",\"@trixFocus\",\"@trixBlur\",\"@trixFileAccept\",\"@trixAttachmentAdd\",\"@trixAttachmentRemove\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11]]],null],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,12,null],[1,\"\\n\"]],[\"trix\",\"@initialValue\",\"@trixInitialize\",\"@trixChange\",\"@trixPaste\",\"@trixSelectionChange\",\"@trixFocus\",\"@trixBlur\",\"@trixFileAccept\",\"@trixAttachmentAdd\",\"@trixAttachmentRemove\",\"&default\"],false,[\"trix-editor\",\"yield\"]]",
    "moduleName": "@videoly/ember-components/components/v-editor.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
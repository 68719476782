define("@videoly/ember-components/components/v-pagination/watch/lock-to-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    watch: function (paged, callback) {
      paged.on('invalidPage', function (event) {
        if (event.page < 1) {
          paged.set('page', 1);
        } else if (event.page > event.totalPages) {
          paged.set('page', event.totalPages);
        }
        callback();
      });
    }
  };
});
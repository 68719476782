define("@videoly/ember-components/components/v-pagination/local/paged-array", ["exports", "@ember/array/proxy", "@ember/object/evented", "@videoly/ember-components/components/v-pagination/util", "@videoly/ember-components/components/v-pagination/divide-into-pages", "@videoly/ember-components/components/v-pagination/watch/lock-to-range", "@ember/array", "rsvp", "@ember/object"], function (_exports, _proxy, _evented, _util, _divideIntoPages, _lockToRange, _array, _rsvp, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-observers */
  /* eslint-disable ember/no-classic-classes */
  var _default = _exports.default = _proxy.default.extend(_evented.default, {
    page: 1,
    perPage: 10,
    totalPages: 1,
    divideObj: function () {
      return _divideIntoPages.default.create({
        perPage: this.perPage,
        all: this.content
      });
    },
    arrangedContent: (0, _object.computed)('content.[]', 'page', 'perPage', function () {
      return this.divideObj().objsForPage(this.page);
    }),
    setPage: function (page) {
      _util.default.log('setPage ' + page);
      return this.set('page', page);
    },
    watchPage: (0, _object.observer)('page', 'totalPages', function () {
      var page = this.page;
      var totalPages = this.totalPages;
      this.trigger('pageChanged', page);
      if (page < 1 || page > totalPages) {
        this.trigger('invalidPage', {
          page: page,
          totalPages: totalPages,
          array: this
        });
      }
    }),
    then: function (success, failure) {
      var content = (0, _array.A)(this.content);
      var me = this;
      var promise;
      if (content.then) {
        promise = content.then(function () {
          return success(me);
        }, failure);
      } else {
        promise = new _rsvp.Promise(function (resolve) {
          resolve(success(me));
        });
      }
      return promise;
    },
    lockToRange: function () {
      _lockToRange.default.watch(this);
    }
  });
});
define("@videoly/ember-components/components/v-loader", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="v-loader" ...attributes>
    <VEmpty @isCentered={{true}}>
      <:icon>
        <v-loader />
      </:icon>
      <:title>
        {{@title}}
      </:title>
      <:description>
        {{@description}}
      </:description>
    </VEmpty>
  </div>
  
  {{yield}}
  
  */
  {
    "id": "jcPM+GoB",
    "block": "[[[11,0],[24,0,\"v-loader\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@isCentered\"],[true]],[[\"icon\",\"title\",\"description\"],[[[[1,\"\\n      \"],[10,\"v-loader\"],[12],[13],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"]],[]],[[[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[18,4,null],[1,\"\\n\"]],[\"&attrs\",\"@title\",\"@description\",\"&default\"],false,[\"v-empty\",\"yield\"]]",
    "moduleName": "@videoly/ember-components/components/v-loader.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
define("@videoly/ember-components/components/v-empty", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="v-empty
    {{if @isCentered "v-empty--centered"}}"
    ...attributes
  >
    {{#if (has-block "icon")}}
      <div class="v-empty__icon">
        {{yield to="icon"}}
      </div>
    {{/if}}
  
    <div class="v-empty__title">
      {{yield to="title"}}
    </div>
  
    {{#if (has-block "description")}}
      <div class="v-empty__description">
        {{yield to="description"}}
      </div>
    {{/if}}
  
    {{#if (has-block "button")}}
      <div class="v-empty__button">
        {{yield to="button"}}
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "aWylwZRh",
    "block": "[[[11,0],[16,0,[29,[\"v-empty\\n  \",[52,[30,1],\"v-empty--centered\"]]]],[17,2],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[10,0],[14,0,\"v-empty__icon\"],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"v-empty__title\"],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[48,[30,5]],[[[1,\"    \"],[10,0],[14,0,\"v-empty__description\"],[12],[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"    \"],[10,0],[14,0,\"v-empty__button\"],[12],[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@isCentered\",\"&attrs\",\"&icon\",\"&title\",\"&description\",\"&button\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "@videoly/ember-components/components/v-empty.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
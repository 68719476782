define("@videoly/ember-components/components/v-datepicker/buttons", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="v-datepicker-buttons"
    {{did-insert @registerButtonsUse}}
    data-test-datepicker-buttons
    ...attributes>
    {{#if @isTimeUsed}}
      <v-toggle
        class="v-datepicker-buttons__toggle"
        label-text="Time"
        checked={{@isTimeVisible}}
        label-type="outside"
        {{on 'toggleChange' @toggleChange}}
        data-test-datepicker-buttons-toggle
      />
    {{/if}}
  
    <v-button
      class="v-datepicker-buttons__button v-datepicker-buttons__button-cancel"
      text="Cancel"
      theme="secondary"
      {{on 'buttonClick' @handleButtonCancelClick}}
      data-test-datepicker-buttons-button-cancel
    />
    <v-button
      class="v-datepicker-buttons__button v-datepicker-buttons__button-save"
      disabled={{@saveIsDisabled}}
      text="Save date"
      {{on 'buttonClick' @handleButtonSaveClick}}
      data-test-datepicker-buttons-button-save
    />
  
    {{yield}}
  </div>
  
  */
  {
    "id": "fi80TfR7",
    "block": "[[[11,0],[24,0,\"v-datepicker-buttons\"],[17,1],[4,[38,0],[[30,2]],null],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"    \"],[11,\"v-toggle\"],[24,0,\"v-datepicker-buttons__toggle\"],[24,\"label-text\",\"Time\"],[16,\"checked\",[30,4]],[24,\"label-type\",\"outside\"],[4,[38,2],[\"toggleChange\",[30,5]],null],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[11,\"v-button\"],[24,0,\"v-datepicker-buttons__button v-datepicker-buttons__button-cancel\"],[24,\"text\",\"Cancel\"],[24,\"theme\",\"secondary\"],[4,[38,2],[\"buttonClick\",[30,6]],null],[12],[13],[1,\"\\n  \"],[11,\"v-button\"],[24,0,\"v-datepicker-buttons__button v-datepicker-buttons__button-save\"],[16,\"disabled\",[30,7]],[24,\"text\",\"Save date\"],[4,[38,2],[\"buttonClick\",[30,8]],null],[12],[13],[1,\"\\n\\n  \"],[18,9,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@registerButtonsUse\",\"@isTimeUsed\",\"@isTimeVisible\",\"@toggleChange\",\"@handleButtonCancelClick\",\"@saveIsDisabled\",\"@handleButtonSaveClick\",\"&default\"],false,[\"did-insert\",\"if\",\"on\",\"yield\"]]",
    "moduleName": "@videoly/ember-components/components/v-datepicker/buttons.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});
define("@videoly/ember-components/components/v-pagination/lib/page-items", ["exports", "@ember/object", "@ember/array", "@videoly/ember-components/components/v-pagination/lib/truncate-pages", "@videoly/ember-components/components/v-pagination/util", "@videoly/ember-components/components/v-pagination/util/safe-get"], function (_exports, _object, _array, _truncatePages, _util, _safeGet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class PageItems extends _object.default.extend(_safeGet.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "leftEdge", 1);
      this.rightEdge = this.numPagesToShow;
    }
    get pageItemsAll() {
      const currentPage = this.getInt('currentPage');
      const totalPages = this.getInt('totalPages');
      _util.default.log(`PageNumbers#pageItems, currentPage ${currentPage}, totalPages ${totalPages}`);
      let res = (0, _array.A)([]);
      for (let i = 1; i <= totalPages; i++) {
        res.push({
          page: i,
          current: currentPage === i,
          dots: false
        });
      }
      return res;
    }
    get pageItemsTruncated() {
      const currentPage = this.getInt('currentPage');
      const totalPages = this.getInt('totalPages');
      const numPagesToShow = this.getInt('numPagesToShow');
      const showFL = this.showFL;
      const t = _truncatePages.default.create({
        numPagesToShow,
        currentPage,
        totalPages,
        showFL
      });
      const pages = t.get('pagesToShow');
      let prev = -1;
      return pages.map(function (page) {
        const h = {
          page,
          current: currentPage === page,
          dots: prev > 0 && prev + 1 !== page
        };
        prev = page;
        return h;
      });
    }
    get pageItems() {
      if (this.truncatePages) {
        return this.pageItemsTruncated;
      } else {
        return this.pageItemsAll;
      }
    }
  }
  _exports.default = PageItems;
});
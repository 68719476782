define("@videoly/ember-trix-next/components/trix-editor", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (has-block)}}
    {{yield (hash
      toolbar=(component 'trix-editor/toolbar' toolbarId=this.toolbarId)
      editor=(component 'trix-editor/editor'
        toolbarId=this.toolbarId
        inputId=this.inputId
        noop=this.noop
      )
    )}}
  {{else}}
    <input id="{{this.inputId}}" value="{{@initialValue}}" type="hidden" data-test-trix-input />
    <trix-editor
      data-test-trix-editor
      input="{{this.inputId}}"
      {{on 'trix-initialize' (or @trixInitialize this.noop)}}
      {{on 'trix-change' (or @trixChange this.noop)}}
      {{on 'trix-paste' (or @trixPaste this.noop)}}
      {{on 'trix-selection-change' (or @trixSelectionChange this.noop)}}
      {{on 'trix-focus' (or @trixFocus this.noop)}}
      {{on 'trix-blur' (or @trixBlur this.noop)}}
      {{on 'trix-file-accept' (or @trixFileAccept this.noop)}}
      {{on 'trix-attachment-add' (or @trixAttachmentAdd this.noop)}}
      {{on 'trix-attachment-remove' (or @trixAttachmentRemove this.noop)}}
      ...attributes
    />
  {{/if}}
  
  */
  {
    "id": "u9Zd92Zd",
    "block": "[[[41,[48,[30,12]],[[[1,\"  \"],[18,12,[[28,[37,3],null,[[\"toolbar\",\"editor\"],[[50,\"trix-editor/toolbar\",0,null,[[\"toolbarId\"],[[30,0,[\"toolbarId\"]]]]],[50,\"trix-editor/editor\",0,null,[[\"toolbarId\",\"inputId\",\"noop\"],[[30,0,[\"toolbarId\"]],[30,0,[\"inputId\"]],[30,0,[\"noop\"]]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"input\"],[15,1,[29,[[30,0,[\"inputId\"]]]]],[15,2,[29,[[30,1]]]],[14,\"data-test-trix-input\",\"\"],[14,4,\"hidden\"],[12],[13],[1,\"\\n  \"],[11,\"trix-editor\"],[24,\"data-test-trix-editor\",\"\"],[16,\"input\",[29,[[30,0,[\"inputId\"]]]]],[17,2],[4,[38,5],[\"trix-initialize\",[28,[37,6],[[30,3],[30,0,[\"noop\"]]],null]],null],[4,[38,5],[\"trix-change\",[28,[37,6],[[30,4],[30,0,[\"noop\"]]],null]],null],[4,[38,5],[\"trix-paste\",[28,[37,6],[[30,5],[30,0,[\"noop\"]]],null]],null],[4,[38,5],[\"trix-selection-change\",[28,[37,6],[[30,6],[30,0,[\"noop\"]]],null]],null],[4,[38,5],[\"trix-focus\",[28,[37,6],[[30,7],[30,0,[\"noop\"]]],null]],null],[4,[38,5],[\"trix-blur\",[28,[37,6],[[30,8],[30,0,[\"noop\"]]],null]],null],[4,[38,5],[\"trix-file-accept\",[28,[37,6],[[30,9],[30,0,[\"noop\"]]],null]],null],[4,[38,5],[\"trix-attachment-add\",[28,[37,6],[[30,10],[30,0,[\"noop\"]]],null]],null],[4,[38,5],[\"trix-attachment-remove\",[28,[37,6],[[30,11],[30,0,[\"noop\"]]],null]],null],[12],[13],[1,\"\\n\"]],[]]]],[\"@initialValue\",\"&attrs\",\"@trixInitialize\",\"@trixChange\",\"@trixPaste\",\"@trixSelectionChange\",\"@trixFocus\",\"@trixBlur\",\"@trixFileAccept\",\"@trixAttachmentAdd\",\"@trixAttachmentRemove\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"on\",\"or\"]]",
    "moduleName": "@videoly/ember-trix-next/components/trix-editor.hbs",
    "isStrictMode": false
  });
  class TrixEditorComponent extends _component2.default {
    constructor() {
      super(...arguments);
      const now = Date.now();
      this.toolbarId = `trix-toolbar-${now}`;
      this.inputId = `trix-input-${now}`;
    }
    noop() {}
  }
  _exports.default = TrixEditorComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TrixEditorComponent);
});
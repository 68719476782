define("@videoly/ember-components/components/v-input-submit", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="v-input-submit
    {{if @isDisabled "v-input-submit--disabled"}}"
    ...attributes
  >
  
    <div class="v-input-submit__input">
      <input
        class="v-input-submit__text"
        type="text"
        disabled={{@isDisabled}}
        value={{@value}}
        placeholder={{this.placeholder}}
        oninput={{this.onInput}}
        onkeyup={{this.onKeyup}}
        aria-label="search"
      />
      {{#if this.shouldShowClearButton}}
        <v-icon-button
          class="v-input-submit__clear"
          theme="ghost"
          {{on "iconButtonClick" this.clear}}
        >
          <v-icon name="Lined_Close"/>
        </v-icon-button>
      {{/if}}
    </div>
  
    {{#if (has-block "button")}}
      {{yield to="button"}}
    {{else}}
      <v-button
        class="v-input-submit__button"
        disabled={{@isDisabled}}
        text={{this.buttonText}}
        theme="{{if @isHighlighted "primary" "secondary"}}"
        {{on "buttonClick" @onSubmit}}
      />
    {{/if}}
  
  </div>
  
  */
  {
    "id": "mzqQDlry",
    "block": "[[[11,0],[16,0,[29,[\"v-input-submit\\n  \",[52,[30,1],\"v-input-submit--disabled\"]]]],[17,2],[12],[1,\"\\n\\n  \"],[10,0],[14,0,\"v-input-submit__input\"],[12],[1,\"\\n    \"],[10,\"input\"],[14,0,\"v-input-submit__text\"],[15,\"disabled\",[30,1]],[15,2,[30,3]],[15,\"placeholder\",[30,0,[\"placeholder\"]]],[15,\"oninput\",[30,0,[\"onInput\"]]],[15,\"onkeyup\",[30,0,[\"onKeyup\"]]],[14,\"aria-label\",\"search\"],[14,4,\"text\"],[12],[13],[1,\"\\n\"],[41,[30,0,[\"shouldShowClearButton\"]],[[[1,\"      \"],[11,\"v-icon-button\"],[24,0,\"v-input-submit__clear\"],[24,\"theme\",\"ghost\"],[4,[38,1],[\"iconButtonClick\",[30,0,[\"clear\"]]],null],[12],[1,\"\\n        \"],[10,\"v-icon\"],[14,3,\"Lined_Close\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[48,[30,6]],[[[1,\"    \"],[18,6,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,\"v-button\"],[24,0,\"v-input-submit__button\"],[16,\"disabled\",[30,1]],[16,\"text\",[30,0,[\"buttonText\"]]],[16,\"theme\",[29,[[52,[30,4],\"primary\",\"secondary\"]]]],[4,[38,1],[\"buttonClick\",[30,5]],null],[12],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isDisabled\",\"&attrs\",\"@value\",\"@isHighlighted\",\"@onSubmit\",\"&button\"],false,[\"if\",\"on\",\"has-block\",\"yield\"]]",
    "moduleName": "@videoly/ember-components/components/v-input-submit.hbs",
    "isStrictMode": false
  });
  let VInputSubmitComponent = _exports.default = (_class = class VInputSubmitComponent extends _component2.default {
    get shouldShowClearButton() {
      return this.args.value && !this.args.isDisabled;
    }
    get placeholder() {
      var _this$args$placeholde;
      return (_this$args$placeholde = this.args.placeholder) !== null && _this$args$placeholde !== void 0 ? _this$args$placeholde : '';
    }
    get buttonText() {
      var _this$args$buttonText;
      return (_this$args$buttonText = this.args.buttonText) !== null && _this$args$buttonText !== void 0 ? _this$args$buttonText : 'Search';
    }
    onInput(e) {
      this.args.onInput(e.target.value);
    }
    onKeyup(e) {
      if (e.key === 'Enter') {
        this.args.onSubmit();
      }
    }
    clear() {
      this.args.onInput('');
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VInputSubmitComponent);
});
define("@videoly/ember-components/components/v-pagination/validate", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Validate extends _object.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "internalErrors", []);
    }
    internalError(str, obj) {
      this.internalErrors.push(str);
      console.warn(str);
      if (obj) {
        console.warn(obj);
      }
    }
    getLastInternalError() {
      return this.internalErrors[this.internalErrors.length - 1];
    }
    clear() {
      this.internalErrors = [];
    }
  }
  var _default = _exports.default = new Validate();
});
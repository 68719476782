define("@videoly/ember-components/components/v-pagination/lib/truncate-pages", ["exports", "@ember/object", "@ember/array", "@videoly/ember-components/components/v-pagination/util/safe-get"], function (_exports, _object, _array, _safeGet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class TruncatePages extends _object.default.extend(_safeGet.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "numPagesToShow", 10);
      _defineProperty(this, "showFL", false);
      _defineProperty(this, "currentPage", null);
      _defineProperty(this, "totalPages", null);
    }
    isValidPage(page) {
      page = parseInt(page);
      var totalPages = this.getInt('totalPages');
      return page > 0 && page <= totalPages;
    }
    get pagesToShow() {
      const res = [];
      const numPages = this.getInt('numPagesToShow');
      const currentPage = this.getInt('currentPage');
      const totalPages = this.getInt('totalPages');
      const showFL = this.showFL;
      let start = currentPage - parseInt(numPages / 2, 10);
      if (start < 1) start = 1;
      let end = start + numPages - 1;
      if (end > totalPages) {
        end = totalPages;
        start = end - numPages + 1;
        if (start < 1) start = 1;
      }
      if (showFL && start > 1) res.push(1);
      if (showFL && start == 3) res.push(2);
      for (let k = start; k <= end; ++k) res.push(k);
      if (showFL && end == totalPages - 2) res.push(totalPages - 1);
      if (showFL && end < totalPages) res.push(totalPages);
      return (0, _array.A)(res);
    }
  }
  _exports.default = TruncatePages;
});
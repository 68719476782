define("@videoly/ember-components/utils/create-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createEvent;
  function createEvent(eventName) {
    let detail = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return new CustomEvent(eventName, {
      detail,
      bubbles: true,
      cancelable: true
    });
  }
});
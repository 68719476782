define("@videoly/ember-components/components/v-pagination/util", ["exports", "@ember/object", "@ember/array"], function (_exports, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Util extends _object.default {
    log() {}
    isBlank(obj) {
      if (obj === 0) {
        return false;
      }
      return !obj || obj === '';
    }
    keysOtherThan(params, excludeKeys) {
      excludeKeys = (0, _array.A)(excludeKeys);
      var res = [];
      for (var key in params) {
        if (!excludeKeys.includes(key)) {
          res.push(key);
        }
      }
      return res;
    }
    paramsOtherThan(params, excludeKeys) {
      var res = {};
      var keys = this.keysOtherThan(params, excludeKeys);
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        var val = params[key];
        res[key] = val;
      }
      return res;
    }
    mergeHashes(a, b) {
      var res = {};
      var val;
      var key;
      for (key in a) {
        val = a[key];
        res[key] = val;
      }
      for (key in b) {
        val = b[key];
        res[key] = val;
      }
      return res;
    }
    isFunction(obj) {
      return typeof obj === 'function';
    }
    getHashKeyForValue(hash, targetVal) {
      for (var k in hash) {
        var val = hash[k];
        if (val === targetVal) {
          return k;
        } else if (this.isFunction(targetVal) && targetVal(val)) {
          return k;
        }
      }
      return undefined;
    }
  }
  var _default = _exports.default = new Util();
});
define("@videoly/ember-components/components/v-chip", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label
    class="v-chip {{if @checked "v-chip--checked"}}"
    htmlfor={{this.inputId}}
    ...attributes
  >
  
    <input
      class="v-chip__input"
      checked={{@checked}}
      id={{this.inputId}}
      type="checkbox"
    />
  
    <v-tag
      avatar-url={{@avatar}}
      avatar-type={{@avatarType}}
      badge-text={{@badgeText}}
      clickable={{true}}
      color={{if @checked "yellow-outline" "white-outline"}}
      form="squared"
      flag-iso={{@flag}}
      icon-side={{if this.hasActionIcon "right" "none"}}
      size={{@size}}
      text={{@text}}
      {{on "tagClick" (fn @chipChange @checked)}}
    >
      <v-icon
        class="v-chip__action-icon"
        name="Lined_Close"
        size={{@size}}
      />
    </v-tag>
  
  </label>
  
  */
  {
    "id": "i8aF12Q2",
    "block": "[[[11,\"label\"],[16,0,[29,[\"v-chip \",[52,[30,1],\"v-chip--checked\"]]]],[16,\"htmlfor\",[30,0,[\"inputId\"]]],[17,2],[12],[1,\"\\n\\n  \"],[10,\"input\"],[14,0,\"v-chip__input\"],[15,\"checked\",[30,1]],[15,1,[30,0,[\"inputId\"]]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\\n  \"],[11,\"v-tag\"],[16,\"avatar-url\",[30,3]],[16,\"avatar-type\",[30,4]],[16,\"badge-text\",[30,5]],[16,\"clickable\",true],[16,\"color\",[52,[30,1],\"yellow-outline\",\"white-outline\"]],[24,\"form\",\"squared\"],[16,\"flag-iso\",[30,6]],[16,\"icon-side\",[52,[30,0,[\"hasActionIcon\"]],\"right\",\"none\"]],[16,\"size\",[30,7]],[16,\"text\",[30,8]],[4,[38,1],[\"tagClick\",[28,[37,2],[[30,9],[30,1]],null]],null],[12],[1,\"\\n    \"],[10,\"v-icon\"],[14,0,\"v-chip__action-icon\"],[14,3,\"Lined_Close\"],[15,\"size\",[30,7]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[\"@checked\",\"&attrs\",\"@avatar\",\"@avatarType\",\"@badgeText\",\"@flag\",\"@size\",\"@text\",\"@chipChange\"],false,[\"if\",\"on\",\"fn\"]]",
    "moduleName": "@videoly/ember-components/components/v-chip.hbs",
    "isStrictMode": false
  });
  class VChipComponent extends _component2.default {
    get hasActionIcon() {
      const {
        hasActionIcon
      } = this.args;
      return hasActionIcon === undefined ? true : hasActionIcon;
    }
    constructor() {
      super(...arguments);
      this.inputId = `input${Math.floor(Math.random() * Math.floor(10000))}`;
      if (!this.args.text) {
        throw new Error('{{v-chip}} cannot be used without `text` property provided');
      }
    }
  }
  _exports.default = VChipComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VChipComponent);
});
define("@videoly/ember-components/services/banner-controller", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object", "@ember/array", "@ember/application", "@ember/runloop", "ember"], function (_exports, _service, _tracking, _object, _array, _application, _runloop, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const ANIMATION_DURATION = 250;
  let BannerControllerService = _exports.default = (_class = class BannerControllerService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "messages", _descriptor, this);
    }
    get animationDuration() {
      return _ember.default.testing ? 0 : ANIMATION_DURATION;
    }
    initController(_ref) {
      let {
        el,
        duration
      } = _ref;
      this.el = el;
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment')['ember-videoly-components'];
      this.duration = duration !== null && duration !== void 0 ? duration : config.BANNER_HIDE_TIMEOUT;
    }
    send(messageData) {
      var _message$duration;
      const message = _object.default.create(messageData);
      message.duration = (_message$duration = message.duration) !== null && _message$duration !== void 0 ? _message$duration : this.duration;
      if (message.hideCloseButton) {
        message.closeTimeout = setTimeout(this.remove, message.duration, message);
      }
      this.messages.unshiftObject(message);
      (0, _runloop.next)(this, () => {
        message.set('isVisible', true);
      });
    }
    remove(message) {
      (0, _runloop.later)(this, () => {
        this.messages.removeObject(message);
      }, this.animationDuration);
      message.set('isVisible', false);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "messages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initController", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "initController"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "send", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "send"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype)), _class);
});
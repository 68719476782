define("@videoly/ember-components/components/v-select/before-options", ["exports", "@ember/component", "@ember/template-factory", "ember-power-select/components/power-select/before-options"], function (_exports, _component, _templateFactory, _beforeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @searchEnabled}}
    <div class="ember-power-select-search v-select-search">
      <div class="v-select-search__content">
        <div class="v-select-search__icon">
          <v-icon name="Lined_Search"/>
        </div>
        <input type="search" autocomplete="off"
          autocorrect="off" autocapitalize="off"
          spellcheck={{false}} role="combobox"
          class="ember-power-select-search-input v-select-search-input"
          value={{@select.searchText}}
          aria-controls={{@listboxId}}
          placeholder={{@searchPlaceholder}}
          {{on "input" @onInput}}
          {{on "focus" @onFocus}}
          {{on "blur" @onBlur}}
          {{on "keydown" this.handleKeydown}}
          {{did-insert this.focusInput}}
          {{will-destroy this.clearSearch}}
          aria-label="search"
        >
        </div>
    </div>
  {{/if}}
  
  */
  {
    "id": "LyOqCZ2v",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"ember-power-select-search v-select-search\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"v-select-search__content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"v-select-search__icon\"],[12],[1,\"\\n        \"],[10,\"v-icon\"],[14,3,\"Lined_Search\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[11,\"input\"],[24,\"autocomplete\",\"off\"],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"],[16,\"spellcheck\",false],[24,\"role\",\"combobox\"],[24,0,\"ember-power-select-search-input v-select-search-input\"],[16,2,[30,2,[\"searchText\"]]],[16,\"aria-controls\",[30,3]],[16,\"placeholder\",[30,4]],[24,\"aria-label\",\"search\"],[24,4,\"search\"],[4,[38,1],[\"input\",[30,5]],null],[4,[38,1],[\"focus\",[30,6]],null],[4,[38,1],[\"blur\",[30,7]],null],[4,[38,1],[\"keydown\",[30,0,[\"handleKeydown\"]]],null],[4,[38,2],[[30,0,[\"focusInput\"]]],null],[4,[38,3],[[30,0,[\"clearSearch\"]]],null],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@searchEnabled\",\"@select\",\"@listboxId\",\"@searchPlaceholder\",\"@onInput\",\"@onFocus\",\"@onBlur\"],false,[\"if\",\"on\",\"did-insert\",\"will-destroy\"]]",
    "moduleName": "@videoly/ember-components/components/v-select/before-options.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _beforeOptions.default {});
});
define("@videoly/ember-components/helpers/format-date", ["exports", "@ember/component/helper", "date-fns/format"], function (_exports, _helper, _format2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatDate = formatDate;
  function formatDate(_ref /*, hash*/) {
    let [date, _format] = _ref;
    return (0, _format2.default)(date, _format);
  }
  var _default = _exports.default = (0, _helper.helper)(formatDate);
});
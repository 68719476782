define("@videoly/ember-components/components/v-select/trigger", ["exports", "@ember/component", "@ember/template-factory", "ember-power-select/components/power-select/trigger"], function (_exports, _component, _templateFactory, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @select.selected}}
    {{#if @selectedItemComponent}}
      {{component @selectedItemComponent extra=(readonly @extra) option=(readonly @select.selected) select=(readonly @select)}}
    {{else}}
      <div class="ember-power-select-selected-item">{{yield @select.selected @select}}</div>
    {{/if}}
    {{#if (and @allowClear (not @select.disabled))}}
      <span
        class="ember-power-select-clear-btn"
        {{on "mousedown" this.clear}}
        {{on "touchstart" this.clear}}
        role="button"
      >
        <v-icon name="Lined_Close_Small" />
      </span>
    {{/if}}
  {{else}}
    {{component @placeholderComponent placeholder=@placeholder}}
  {{/if}}
  <div class="v-select-status-icon">
    <v-icon name="Lined_Arrow_Down"/>
  </div>
  
  */
  {
    "id": "q6Ug5LaT",
    "block": "[[[41,[30,1,[\"selected\"]],[[[41,[30,2],[[[1,\"    \"],[46,[30,2],null,[[\"extra\",\"option\",\"select\"],[[28,[37,2],[[30,3]],null],[28,[37,2],[[30,1,[\"selected\"]]],null],[28,[37,2],[[30,1]],null]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"ember-power-select-selected-item\"],[12],[18,7,[[30,1,[\"selected\"]],[30,1]]],[13],[1,\"\\n\"]],[]]],[41,[28,[37,4],[[30,4],[28,[37,5],[[30,1,[\"disabled\"]]],null]],null],[[[1,\"    \"],[11,1],[24,0,\"ember-power-select-clear-btn\"],[24,\"role\",\"button\"],[4,[38,6],[\"mousedown\",[30,0,[\"clear\"]]],null],[4,[38,6],[\"touchstart\",[30,0,[\"clear\"]]],null],[12],[1,\"\\n      \"],[10,\"v-icon\"],[14,3,\"Lined_Close_Small\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"  \"],[46,[30,5],null,[[\"placeholder\"],[[30,6]]],null],[1,\"\\n\"]],[]]],[10,0],[14,0,\"v-select-status-icon\"],[12],[1,\"\\n  \"],[10,\"v-icon\"],[14,3,\"Lined_Arrow_Down\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@select\",\"@selectedItemComponent\",\"@extra\",\"@allowClear\",\"@placeholderComponent\",\"@placeholder\",\"&default\"],false,[\"if\",\"component\",\"readonly\",\"yield\",\"and\",\"not\",\"on\"]]",
    "moduleName": "@videoly/ember-components/components/v-select/trigger.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _trigger.default {});
});
define("@videoly/ember-components/components/v-pagination/util/safe-get", ["exports", "@videoly/ember-components/components/v-pagination/util"], function (_exports, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getInt = getInt;
  function getInt(prop) {
    var raw = this.get(prop);
    if (raw === 0 || raw === '0') {
      // do nothing
    } else if (_util.default.isBlank(raw)) {
      throw new Error(`Pagination error: no int for ${prop} val is ${raw}`);
    }
    return parseInt(raw);
  }
});
define("@videoly/ember-components/utils/class-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getClassNamesFromObject;
  /**
   * @param {Record<string, boolean>} classList
   * @returns {string}
   */
  function getClassNamesFromObject(classList) {
    const result = [];
    for (const className in classList) {
      if (className && classList[className]) {
        result.push(className);
      }
    }
    return result.join(' ');
  }
});
define("@videoly/ember-components/components/v-pagination/divide-into-pages", ["exports", "@ember/object", "@ember/array"], function (_exports, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DivideIntoPages extends _object.default {
    objsForPage(page) {
      var range = this.range(page);
      const all = (0, _array.A)(this.all);
      return (0, _array.A)(all.slice(range.start, range.end + 1));
    }
    range(page) {
      var perPage = parseInt(this.perPage);
      var s = (parseInt(page) - 1) * perPage;
      var e = s + perPage - 1;
      return {
        start: s,
        end: e
      };
    }
  }
  _exports.default = DivideIntoPages;
});
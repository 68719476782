define("@videoly/ember-components/initializers/import-stencil-collections", ["exports", "@videoly/universe/dist/loader"], function (_exports, _loader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    // No-op
    const config = application.resolveRegistration('config:environment');
    const assetsPath = config['ember-videoly-components'].assetsPath;
    (0, _loader.applyPolyfills)().then(function () {
      (0, _loader.defineCustomElements)(window, {
        resourcesUrl: assetsPath
      });
    });
  }
  var _default = _exports.default = {
    initialize
  };
});
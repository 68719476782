define("@videoly/ember-components/components/v-tooltip", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/application"], function (_exports, _component, _templateFactory, _component2, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <EmberTooltip
    @arrowClass="v-tooltip__arrow"
    @tooltipClass="v-tooltip
      {{if @isAdvanced "v-tooltip--advanced"}}
      {{this.themeClass}}"
    @delay={{this.delay}}
    @event={{this.event}}
    @isShown={{@isShown}}
    @popperContainer={{this.popperContainer}}
    @popperOptions={{this.popperOptions}}
    @spacing={{12}}
    @side={{this.side}}
    ...attributes
  >
    {{#if (has-block)}}
      {{yield this}}
    {{else}}
      {{@text}}
    {{/if}}
  
    {{#if @withCloseButton}}
      <v-icon-button
        class="v-tooltip__close-button"
        size="small"
        {{on "iconButtonClick" @onTooltipClose}}
      >
        <v-icon name="Lined_Close_Small"/>
      </v-icon-button>
    {{/if}}
  </EmberTooltip>
  
  */
  {
    "id": "oVGI9LlF",
    "block": "[[[8,[39,0],[[17,1]],[[\"@arrowClass\",\"@tooltipClass\",\"@delay\",\"@event\",\"@isShown\",\"@popperContainer\",\"@popperOptions\",\"@spacing\",\"@side\"],[\"v-tooltip__arrow\",[29,[\"v-tooltip\\n    \",[52,[30,2],\"v-tooltip--advanced\"],\"\\n    \",[30,0,[\"themeClass\"]]]],[30,0,[\"delay\"]],[30,0,[\"event\"]],[30,3],[30,0,[\"popperContainer\"]],[30,0,[\"popperOptions\"]],12,[30,0,[\"side\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"    \"],[18,7,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,4]],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[11,\"v-icon-button\"],[24,0,\"v-tooltip__close-button\"],[24,\"size\",\"small\"],[4,[38,4],[\"iconButtonClick\",[30,6]],null],[12],[1,\"\\n      \"],[10,\"v-icon\"],[14,3,\"Lined_Close_Small\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@isAdvanced\",\"@isShown\",\"@text\",\"@withCloseButton\",\"@onTooltipClose\",\"&default\"],false,[\"ember-tooltip\",\"if\",\"has-block\",\"yield\",\"on\"]]",
    "moduleName": "@videoly/ember-components/components/v-tooltip.hbs",
    "isStrictMode": false
  });
  class VTooltip extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "popperOptions", {
        preventOverflow: {
          escapeWithReference: false
        }
      });
    }
    get themeClass() {
      return this.args.theme === 'yellow' ? 'v-tooltip--theme-yellow' : '';
    }
    get popperContainer() {
      if (this.args.popperContainer) {
        return this.args.popperContainer;
      }
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return config.environment === 'test' ? false : 'body';
    }
    get side() {
      var _this$args$side;
      return (_this$args$side = this.args.side) !== null && _this$args$side !== void 0 ? _this$args$side : 'top';
    }
    get event() {
      var _this$args$event;
      return (_this$args$event = this.args.event) !== null && _this$args$event !== void 0 ? _this$args$event : 'hover';
    }
    get delay() {
      return this.args.delay ? Number(this.args.delay) : 200;
    }
  }
  _exports.default = VTooltip;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, VTooltip);
});
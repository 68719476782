define("@videoly/ember-components/components/v-select/multiple/trigger", ["exports", "@ember/component", "@ember/template-factory", "ember-power-select/components/power-select-multiple/trigger"], function (_exports, _component, _templateFactory, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @select.selected}}
    {{#if @selectedItemComponent}}
      {{component @selectedItemComponent extra=(readonly @extra) option=(readonly @select.selected) select=(readonly @select)}}
    {{else}}
      <div class="ember-power-select-selected-item v-select-selected-item">
        <v-badge text={{@select.selected.length}} size={{@extra.size}} color="white" class="v-select-selected-item__badge" />
        <span class="v-select-selected-item__text">
          {{if @extra.triggerComponentText @extra.triggerComponentText 'Selected items'}}
        </span>
      </div>
    {{/if}}
  {{else}}
    {{component @placeholderComponent placeholder=@placeholder}}
  {{/if}}
  <div class="v-select-status-icon">
    <v-icon name="Lined_Arrow_Down"/>
  </div>
  
  */
  {
    "id": "7Ff47tX6",
    "block": "[[[41,[30,1,[\"selected\"]],[[[41,[30,2],[[[1,\"    \"],[46,[30,2],null,[[\"extra\",\"option\",\"select\"],[[28,[37,2],[[30,3]],null],[28,[37,2],[[30,1,[\"selected\"]]],null],[28,[37,2],[[30,1]],null]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"ember-power-select-selected-item v-select-selected-item\"],[12],[1,\"\\n      \"],[10,\"v-badge\"],[15,\"text\",[30,1,[\"selected\",\"length\"]]],[15,\"size\",[30,3,[\"size\"]]],[14,\"color\",\"white\"],[14,0,\"v-select-selected-item__badge\"],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"v-select-selected-item__text\"],[12],[1,\"\\n        \"],[1,[52,[30,3,[\"triggerComponentText\"]],[30,3,[\"triggerComponentText\"]],\"Selected items\"]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[46,[30,4],null,[[\"placeholder\"],[[30,5]]],null],[1,\"\\n\"]],[]]],[10,0],[14,0,\"v-select-status-icon\"],[12],[1,\"\\n  \"],[10,\"v-icon\"],[14,3,\"Lined_Arrow_Down\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@select\",\"@selectedItemComponent\",\"@extra\",\"@placeholderComponent\",\"@placeholder\"],false,[\"if\",\"component\",\"readonly\"]]",
    "moduleName": "@videoly/ember-components/components/v-select/multiple/trigger.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _trigger.default {});
});